import React from "react";
import { Layout } from "components";

import "./legal-returns-app.scss";

const LegalReturns = () => {
  return (
    <Layout>
      <div className="wrapper">
        <div className="legal-returns-app">
          <h1 className="legal-returns-app__title">Returns Policy</h1>
          <p>&nbsp;</p>
          <h3>Warranty and Refund Policy</h3>
          <p>&nbsp;</p>
          <p>
            <strong>Safedome Hardware Limited Warranty</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Maxwell Forest Pty Ltd (“Maxwell Forest”), supplier of the Safedome
            Hardware product (“Hardware”), warrants to the recipient (“You”)
            that the Hardware will be free from defects in materials and
            workmanship for a period of one (1) year from the date of delivery
            following the original retail purchase (the “Warranty Period”). If
            the Hardware fails to conform to this Limited Warranty during the
            Warranty Period, Maxwell Forest will, at its sole discretion, either
            (a) replace any defective Hardware or component; or (b) accept the
            return of the Hardware and refund the money actually paid by the
            original purchaser for the Hardware. Replacement may be made with a
            new or refurbished product or components, at Maxwell Forest’ sole
            discretion. If the Hardware or a component incorporated within it is
            no longer available, Maxwell Forest may replace the Hardware with a
            similar product of similar function, at Maxwell Forest’s sole
            discretion. This is your sole and exclusive remedy for breach of
            this Limited Warranty. Any Hardware that has been replaced under
            this Limited Warranty will be covered by the terms of this Limited
            Warranty for the longer of ninety (90) days from the date of
            delivery or the remaining Warranty Period. This Limited Warranty is
            transferable from the original purchaser to subsequent owners, but
            the Warranty Period will not be extended in duration or expanded in
            coverage for any such transfer.
          </p>
          <p>&nbsp;</p>
          <p>
            The Limited Warranty does not apply to: (i) any products or services
            other than the physical components of the Hardware; (ii) consumable
            parts, unless consumable part failure has occurred due to a
            mechanical or design defect; (iii) to cosmetic damage; (iii) to
            data, information, or software, including any such materials stored
            or embedded on the Hardware. Without limiting the foregoing, Maxwell
            Forest shall not be responsible for, and hereby disclaims any
            liability for, Your or any third party’s software, firmware,
            information, or memory data contained in, stored on, or integrated
            with any Hardware that is returned to Maxwell Forest for repair,
            whether under warranty or not.
          </p>
          <p>
            Maxwell Forest will have no obligation to the extent that any
            failure of the Hardware to comply with the limited warranty set
            forth herein that results from or is otherwise attributable to: (i)
            negligence or misuse or abuse of the Hardware; (ii) use of the
            Hardware other than in accordance with Maxwell Forest’s published
            specifications or user manual; (iii) modifications, alterations or
            repairs to the Hardware made by a party other than Maxwell Forest or
            a party authorized by Maxwell Forest; (iv) any failure by You or a
            third party to comply with environmental and storage requirements
            for the Hardware specified by Maxwell Forest, including, without
            limitation, temperature or humidity ranges; (v) use of the Hardware
            in combination with any third-party devices or products that have
            not been provided or recommended by Maxwell Forest; or (vi) loss or
            theft, accident, or exposure to liquids or extreme environmental
            conditions.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, MAXWELL FOREST EXPRESSLY
            DISCLAIMS ALL OTHER WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
            STATUTORY OR IMPLIED (INCLUDING, THE SALE OF GOODS ACT 1923 (NSW)
            AND OTHER SIMILAR STATE BASED LEGISLATION AND THE UNITED NATIONS
            CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS),
            REGARDING THE HARDWARE, INCLUDING ANY IMPLIED WARRANTY OF
            NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING
            OR USAGE OF TRADE, EXCEPT THAT ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE ARE LIMITED IN
            DURATION TO THE LIMITED WARRANTY PERIOD. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN, OBTAINED FROM MAXWELL FOREST OR ELSEWHERE,
            WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS LIMITED
            WARRANTY. Some states do not allow limitations on how long an
            implied warranty lasts, so the above limitation may not apply to
            you.
          </p>
          <p>&nbsp;</p>
          <p>
            UNDER NO CIRCUMSTANCES WILL MAXWELL FOREST BE LIABLE TO YOU FOR ANY
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE LOSSES OR
            DAMAGES (INCLUDING, BUT NOT LIMITED TO LOSS OF PROFITS, GOODWILL,
            USE, DATA OR OTHER INTANGIBLE LOSSES) OR COSTS OF PROCURING
            SUBSTITUTE PRODUCTS, ARISING OUT OF OR RELATING TO MAXWELL FOREST’S
            BREACH OF THIS LIMITED WARRANTY, YOUR ACCESS TO OR USE OF, OR
            INABILITY TO ACCESS OR USE, THE HARDWARE, WHETHER BASED ON WARRANTY,
            CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL
            THEORY, WHETHER OR NOT MAXWELL FOREST WAS AWARE OF THE POSSIBILITY
            OF SUCH DAMAGE.
          </p>
          <p>
            MAXWELL FOREST AND YOU HAVE AGREED THAT THESE LIMITATIONS WILL
            SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THIS
            LIMITED WARRANTY IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
          </p>
          <p>
            IN NO EVENT WILL MAXWELL FOREST’S LIABILITY TO YOU ARISING OUT OF,
            RELATING TO, OR IN CONNECTION WITH THIS LIMITED WARRANTY, FROM ALL
            CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY (INCLUDING
            NEGLIGENCE), EXCEED THE ACTUAL AMOUNT PAID TO MAXWELL FOREST BY YOU
            FOR THE HARDWARE THAT GIVES RISE TO THE CLAIM.
          </p>
          <p>&nbsp;</p>
          <p>
            Claims process: To obtain remedies under this Warranty, Maxwell
            Forest must receive the Consumer’s claim before the end of the
            Warranty Period. The Consumer must obtain a Return Material
            Authorization ("RMA") number from Maxwell Forest and return the
            defective Device together with proof of purchase to the address
            specified by Maxwell Forest in connection with the RMA. The Consumer
            shall bear the cost of shipping the Device to Maxwell Forest. By
            sending the Device, the Consumer agrees to transfer ownership to
            Maxwell Forest. Maxwell Forest may not return the original Device to
            the Consumer. Maxwell Forest warrants that any repaired or replaced
            Device is covered for the remainder of the original Warranty Period.
            If the claim is justified based on this Warranty, Maxwell Forest
            shall bear the cost of shipping the repaired or replacement Device
            to the Consumer. Any product returned to Maxwell Forest without a
            valid warranty claim or without a RMA number may be rejected,
            returned at sender’s cost (subject to prepayment) or disposed of in
            Maxwell Forest’s sole discretion.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Carbon Fiber Range</strong>
          </p>
          <blockquote type="cite" className="legal-returns-app__blockquote">
            <p>
              <strong>
                <span>ONE-YEAR LIMITED WARRANTY&nbsp;</span>
              </strong>
              <span>
                Safedome&nbsp;Carbon Fiber warrants all products applicable
                against defects in materials and workmanship under normal use
                for a period of ONE (1) YEAR from the date of retail purchase by
                the original end-user consumer. Limited warranty for one-year
                excludes any wear and tear, water damage and abuse you might put
                your product through and excludes SCF Screen Guards. You must
                provide proof of purchase by emailing your receipt to us using
                the Contact Us page on our website. You must also return the
                damaged product to Safedome&nbsp;
              </span>
              Carbon Fiber. If a valid claim is made during the one-year
              period,&nbsp;Safedome Carbon Fiber, at its option, will replace
              the product. A replacement product assumes the remaining warranty
              of the original product IS INVALID. Customer is responsible for
              the incoming and outgoing warranty shipping fees.
            </p>
          </blockquote>
          <p>
            <strong>Disclaimer: </strong>This disclaimer states there is not a
            guarantee of specific results as shown or advertised by our brand by
            using any of our products. Results may vary per user.
          </p>
          <p>&nbsp;</p>
          <p>
            To obtain a RMA number from Maxwell Forest, please follow the
            instructions outlined below.
          </p>
          <p>
            <strong>Refund Policy</strong>
          </p>
          <p>
            If you are the original purchaser of the Safedome Hardware and you
            are not satisfied with it, you may return the Hardware in the
            original transaction, in their original condition, with the original
            receipt and packaging, within thirty (30) days from the date of
            delivery to Maxwell Forest and Maxwell Forest will exchange it or
            provide a full refund back to the original form of payment for the
            original purchase price.
          </p>

          <p>All returns must be authorized. To authorize a return, please follow the instructions outlined below.</p>
          <p>
            <strong>Return Instructions:</strong>
          </p>
          <p>
            Please email us at returns@safedome.com with your name, shipping address, invoice details and the reason for
            the return. We will provide you with an RMA (Return Merchandise Authorization) number.
          </p>
          <p>&nbsp;</p>
          <p>
            To return your product, send it to: Maxwell Forest Pty Ltd, Lvl 1, 85 William Street, Darlinghurst, NSW,
            2010, Australia.
          </p>
          <p>Maxwell Forest is not responsible for shipping costs associated with the return of the order. </p>
          <p>Once the return has been processed, a refund will be issued back to the original form of payment.</p>
        </div>
      </div>
    </Layout>
  );
};

export default LegalReturns;
